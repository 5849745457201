.rainbow {
  text-align: center;
  text-decoration: underline;
  font-size: 32px;
  font-family: monospace;
  letter-spacing: 5px;
  animation: colorRotate 6s linear 0s infinite;
}

@keyframes colorRotate {
  from {
    fill: #94c1f2;
    stroke: #94c1f2;
    /* fill: #6666ff; */
  }
  50% {
    fill: #557eba;
    stroke: #557eba;
    /* fill: #00ff00; */
  }
  100% {
    fill: #94c1f2;
    stroke: #94c1f2;
    /* fill: #6666ff; */
  }
}
